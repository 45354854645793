import { BackendService } from '@app/backend';
import { AppInjector } from '../app-injector';
import { AppCacheService } from './app-cache.service';

export abstract class BaseService {
  protected backend: BackendService;
  protected cache: AppCacheService;

  constructor() {
    const injector = AppInjector.getInjector();
    this.backend = injector.get(BackendService);
    this.cache = injector.get(AppCacheService);
  }
}
