<div class="travaux-menu">
  <a href="javascript:void(0)" class="travaux-menu-btn" (click)="menu.toggle($event)" title="Menu Travaux"> Travaux </a>
  <a
    *ngIf="isConception"
    class="more-info-link"
    href="https://sncf.sharepoint.com/sites/PortailOpenGOV/SitePages/Elle-inclue-une-cr%C3%A9ation-manuelle-des-travaux-voies-en-gare%2C-afin-de-les-visualiser-sur-le-GOV-et-d%C3%A9tecter-les.aspx"
    target="_blank"
  >
    <i class="pi pi-info-circle" pTooltip="Cliquez ici pour découvrir comment utiliser cette nouvelle fonctionnalité"></i>
  </a>
  <p-menu #menu [popup]="true" [model]="menuItems" styleClass="travaux-menu"></p-menu>
</div>
