import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/base.component';
import { Station } from '@app/models';
import { StationService } from '@app/services';
import { BackendServiceError } from '@app/backend';
import { map, tap, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-page-station-selection',
  templateUrl: './page-station-selection.component.html',
  styleUrls: ['./page-station-selection.component.scss'],
})
export class PageStationSelectionComponent extends BaseComponent implements OnInit, OnDestroy {
  stations: Station[] = [];
  currentStation: Station;

  constructor(
    private route: ActivatedRoute,
    private stationService: StationService,
  ) {
    super();
  }

  ngOnInit() {
    // Load stations from backend and get param from URL.
    this.subscriptions.push(
      this.stationService
        .getAllStations()
        .pipe(
          tap(stations => (this.stations = stations)),
          mergeMap(() => this.route.queryParamMap),
          map(paramMap => paramMap.get('station') || ''),
        )
        .subscribe({
          next: stationCodeFromUrl => {
            let currentStationCode: string;
            // Pre-select station if current user can only access one station
            if (this.stations.length === 1) {
              currentStationCode = this.stations[0].code;
              // Or do we have a pre-selected station from the URL?
            } else {
              currentStationCode = stationCodeFromUrl;
            }
            this.selectStation(currentStationCode);
          },
          error: err => this.showError(err.message, err),
        }),
    );
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  //

  selectStation(stationCode: string) {
    if (stationCode) {
      // Update the URL with the selected station code.
      this.router.navigate([], { queryParams: { station: stationCode } });
      this.currentStation = this.stations.find(s => s.code === stationCode);
      this.setPageTitle(`Gare "${this.currentStation.nom}"`);
    } else {
      this.stationService.resetCache();
      this.currentStation = null;
      this.setPageTitle(`Choix d'une gare`);
    }
  }

  /**
   * Load additional info for the current station,
   * store everything in the cache, and redirect the user...
   */
  saveCurrentStationAndGoTo(where: 'calendar' | 'conception' | 'travaux' | 'settings') {
    this.spinner.show();
    if (this.currentStation) {
      // Since the user has decided to work on a specific station, store it in the cache...
      this.stationService.storeStationInCache(this.currentStation);
      // ... and load additional station information (will be added to the cache, too).
      this.subscriptions.push(
        this.stationService.loadExtraStationProps(this.currentStation.code).subscribe({
          next: () => {
            this.spinner.hide();
            this.router.navigate([this.currentStation.code, where]);
          },
          error: (err: BackendServiceError) => {
            this.spinner.hide();
            const msgNoInfra = `Aucune infrastructure n'est chargée pour la gare.`;
            const showMsgNoInfra = err?.errorCode === 404 || err?.message === 'NO_IMPORTED_INFRASTRUCTURE';
            showMsgNoInfra ? this.showError(msgNoInfra) : this.showError(err.message, err);
          },
        }),
      );
    } else {
      this.showError('Veuillez sélectionner une gare');
    }
  }

  private showError(message: string, err?: BackendServiceError) {
    let msg = message;
    if (!msg && err) {
      if (err.message) {
        msg = err.message;
      } else if (err.originalError.error) {
        msg = err.originalError.error;
      }
    }
    if (!msg) {
      msg = `Une erreur technique est survenue.`;
    }
    this.dialog.show({ title: 'Erreur', content: msg });
  }
}
