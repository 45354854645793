import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

import { Subscription } from 'rxjs';

import { DialogService } from '@app/shared';
import { AppInjector } from '@app/app-injector';
import { setTranslate } from './utils';

@Component({
  selector: 'app-base',
  template: '',
  styles: [''],
})
export class BaseComponent {
  protected router: Router;
  // protected route: ActivatedRoute;  -- NB. DO NOT inject here; depends on the component where it's injected
  private title: Title;
  protected spinner: NgxSpinnerService;
  protected dialog: DialogService;
  protected toastr: MessageService;

  protected subscriptions: Subscription[] = [];

  // Variables for moving element
  protected currentPositionX: number;
  protected currentPositionY: number;
  protected lastPositionX: number;
  protected lastPositionY: number;
  protected xOffset = 0;
  protected yOffset = 0;

  /** Moving element */
  protected movingEl: HTMLElement;
  /** Moving header element */
  protected movingHeaderEl: HTMLElement;
  /** Allows to know if we are moving the element */
  protected isMoving: boolean;

  constructor() {
    const injector = AppInjector.getInjector();
    this.router = injector.get(Router);
    // this.route = injector.get(ActivatedRoute);  -- NB. DO NOT inject here; depends on the component where it's injected
    this.title = injector.get(Title);
    this.spinner = injector.get(NgxSpinnerService);
    this.dialog = injector.get(DialogService);
    this.toastr = injector.get(MessageService);
  }

  protected setPageTitle(title: string) {
    this.title.setTitle(`${title} - OpenGOV`);
  }

  protected unsubscribeAll() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Init moving actions for element
   */
  protected initMovingActions(elementClassName: string, headerClassName: string) {
    this.movingEl = document.getElementsByClassName(elementClassName)[0] as HTMLElement;
    this.movingHeaderEl = document.getElementsByClassName(headerClassName)[0] as HTMLElement;

    this.movingHeaderEl.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.movingHeaderEl.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.movingHeaderEl.addEventListener('mouseup', this.onMouseUp.bind(this));
  }

  /**
   * Action on mouse move
   * @param event The mouse event
   */
  protected onMouseMove(event: MouseEvent) {
    if (this.isMoving) {
      this.currentPositionX = event.clientX - this.lastPositionX;
      this.currentPositionY = event.clientY - this.lastPositionY;

      this.xOffset = this.currentPositionX;
      this.yOffset = this.currentPositionY;

      setTranslate(this.currentPositionX, this.currentPositionY, this.movingEl);
    }
  }

  /**
   * Action on mouse down
   * @param event The mouse event
   */
  protected onMouseDown(event: MouseEvent) {
    this.isMoving = true;
    this.movingHeaderEl.style.cursor = 'grabbing';

    this.lastPositionX = event.clientX - this.xOffset;
    this.lastPositionY = event.clientY - this.yOffset;
  }

  /**
   * Action on mouse up
   * @param event The mouse event
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onMouseUp(event: MouseEvent) {
    this.isMoving = false;
    this.movingHeaderEl.style.cursor = 'grab';
    this.lastPositionX = this.currentPositionX;
    this.lastPositionY = this.currentPositionY;
  }

  /**
   * Reset sidebar position
   */
  protected resetElementPosition() {
    this.xOffset = 0;
    this.yOffset = 0;
    this.currentPositionX = undefined;
    this.currentPositionY = undefined;
    this.lastPositionX = undefined;
    this.lastPositionY = undefined;
    setTranslate(0, 0, this.movingEl);
  }
}
