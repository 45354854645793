<p-dialog
  [(visible)]="isDialogVisible"
  [header]="dialogInfo.title"
  [showHeader]="showHeader"
  [modal]="true"
  [style]="{width: dialogInfo.width + 'px'}"
  [baseZIndex]="10000"
  [ngClass]="showHeader ? 'header' : 'no-header'"
>
  <pre class="content">{{ dialogInfo.content }}</pre>
  <p-checkbox
    *ngIf="dialogInfo.checkboxLabel"
    class="p-element"
    [(ngModel)]="dialogInfo.checkboxOk"
    [binary]="true"
    [label]="dialogInfo.checkboxLabel"
    name="checkboxMiquai"
    value="{{ dialogInfo.checkboxOk }}"
  />

  <p-footer>
    <button type="button" (click)="cancel()" class="btn btn-default btn-cancel" [ngClass]="dialogInfo.showCancel ? 'show': 'hide'">
      {{ dialogInfo.cancelButtonLabel }}
    </button>
    <button
      type="button"
      (click)="ok()"
      class="btn btn-default btn-ok"
      [ngClass]="(dialogInfo.showOk ? 'show': 'hide') + ' ' + (dialogInfo.okButtonClass || '')"
    >
      {{ dialogInfo.okButtonLabel }}
    </button>
  </p-footer>
</p-dialog>
