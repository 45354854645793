<app-header></app-header>

<section class="page-info">
  <div class="content">
    <h1>Nouveauté ! 🎉</h1>
    <p>Nouvelle fonctionnalité "Travaux" dans votre application OpenGOV.</p>
    <p>
      <img src="/assets/uploaded-img/calendar-screenshot-annotated.png" class="fluid" alt="Menu travaux au-dessus du calendrier" />
    </p>
    <p>Pour la découvrir, cliquez sur le menu <b>Travaux</b> en haut à droite du calendrier d'adaptation.</p>
    <p>
      Un
      <a
        href="https://sncf.sharepoint.com/sites/PortailOpenGOV/SitePages/Elle-inclue-une-cr%C3%A9ation-manuelle-des-travaux-voies-en-gare%2C-afin-de-les-visualiser-sur-le-GOV-et-d%C3%A9tecter-les.aspx"
        >tutoriel d'utilisation</a
      >
      a été mis à votre disposition sur Sharepoint (ce tutoriel est également accessible en cliquant sur le petit
      <i class="pi pi-info-circle"></i> du menu Travaux).
    </p>
    <div class="buttons">
      <button type="button" class="btn" routerLink="/station">Continuer vers OpenGOV Web</button>
    </div>
  </div>
</section>
