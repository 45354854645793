<section class="date-nav">
  <div class="buttons">
    <span class="button previous" (click)="previousMonth()"> </span>
    <span class="button next" (click)="nextMonth()"> </span>

    <span class="button month" (click)="monthMenu.toggle($event)"> {{ months[currentMonth - 1] | titlecase }} </span>
    <p-menu #monthMenu [popup]="true" [model]="monthMenuItems"></p-menu>

    <span class="button year" (click)="yearMenu.toggle($event)"> {{ currentYear }} </span>
    <p-menu #yearMenu [popup]="true" [model]="yearMenuItems"></p-menu>
  </div>
</section>
