<section class="admin-info">
  <!-- Backend Error displays below -->
  <div class="field-item" *ngIf="backendErrorMsg">
    <p class="error-msg" [innerHTML]="backendErrorMsg"></p>
  </div>

  <h2 class="front-section">Infos de build FRONT</h2>

  <table>
    <thead>
      <th>Propriété</th>
      <th>Valeur</th>
    </thead>
    <tbody>
      <tr>
        <td>
          Version du frontend (harcodée dans
          <span class="blue">package.json</span>)
        </td>
        <td class="value">{{ frontEndVersion }}</td>
      </tr>
      <tr>
        <td>Hash du frontend</td>
        <td class="value">{{ frontEndHash }}</td>
      </tr>
    </tbody>
  </table>

  <h2 class="back-section">Infos de build BACK</h2>

  <p class="infos">
    Les infos ci-dessous sont renvoyées par le endpoint
    <span class="blue">{{ backendURI }}/public/info</span>.
  </p>

  <table *ngIf="info">
    <thead>
      <th>Propriété</th>
      <th>Valeur</th>
    </thead>
    <tr>
      <td>Version du backend (<span class="blue">project.version</span>)</td>
      <td class="value">{{ info["project.version"] }}</td>
    </tr>
    <tr>
      <td>Heure de build (<span class="blue">build.time</span>)</td>
      <td class="value">{{ info["build.time"] }}</td>
    </tr>
    <tr>
      <td>Branche (<span class="blue">git.branch</span>)</td>
      <td class="value">{{ info["git.branch"] }}</td>
    </tr>
    <tr>
      <td>Commit hash (<span class="blue">git.commit</span>)</td>
      <td class="value">{{ info["git.commit"] }}</td>
    </tr>
    <tr>
      <td>Heure du commit (<span class="blue">git.commit.time</span>)</td>
      <td class="value">{{ info["git.commit.time"] }}</td>
    </tr>
  </table>
  <!-- TODO July 2023 : Use the "form-buttons" class from form.scss -->
  <button class="btn btn-cancel" (click)="closeDialogInfo()">Fermer</button>
</section>
