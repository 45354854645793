<div class="validate-journee-type-component">
  <!-- Bouton "Valider journée type" -->
  <div *ngIf="!isGOVJTValidated; else jtNotValidated">
    <button
      type="button"
      class="btn-icon-blue"
      id="btn-validate"
      (click)="validate()"
      pTooltip="Valider la journée type"
      tooltipPosition="bottom"
    >
      <img src="/assets/img/ic-header-validate-jt-default.svg" alt="Valider" />
    </button>
  </div>

  <!-- Bouton "Annuler" -->
  <ng-template #jtNotValidated>
    <div>
      <button type="button" class="btn-validated" id="btn-unvalidate" (click)="unValidate()">
        <img src="/assets/img/ic-header-jt-validated-on.svg" alt="Validée" />
        <span>Validée</span>
      </button>
    </div>
  </ng-template>
</div>
