<section class="date-nav">
  <div class="buttons flex center">
    <span class="button previous" (click)="previous()" [class.disabled]="isDisabledPrev"> </span>
    <span class="button next" (click)="next()" [class.disabled]="isDisabledNext"> </span>

    <span *ngIf="currentMonth" class="button month" (click)="monthMenu.toggle($event)"> {{ months[currentMonth - 1] | titlecase }} </span>
    <p-menu #monthMenu [popup]="true" [model]="monthMenuItems"></p-menu>

    <span *ngIf="selectedYear" class="button year" (click)="yearMenu.toggle($event); toggleYear = !toggleYear">
      {{ currentYearLabel }}
      <img [@rotatedState]="!toggleYear? 'default': 'rotated'" class="arrow" src="/assets/img/arrow-subperiod-down.svg" />
    </span>
    <p-menu #yearMenu [popup]="true" [model]="yearMenuItems" (onHide)="onHide()"></p-menu>
  </div>
</section>
