<div class="certify-component">
  <!-- Bouton "Certifier" -->
  <div *ngIf="!isGovCertified; else notCertified">
    <button type="button" class="btn-icon-blue" id="btn-certify" (click)="certify()" pTooltip="Certifier" tooltipPosition="bottom">
      <img src="/assets/img/ic-header-certify-default.svg" alt="Certifier" />
    </button>
  </div>

  <!-- Bouton "Annuler" -->
  <ng-template #notCertified>
    <div>
      <button type="button" class="btn-validated" id="btn-uncertify" (click)="unCertify()">
        <img src="/assets/img/ic-header-certify-on.svg" alt="Certifié" />
        <span>Certifié</span>
      </button>
    </div>
  </ng-template>
</div>
