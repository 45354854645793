<div class="flex select-button-wrapper">
  <span
    class="flex select-button cancel"
    [ngClass]="{'selected': canceled,
        'not-selected' : validated}"
    (click)="onCancel()"
  >
    <img src="/assets/img/ic-cancel-default.svg" alt="cancel" />
  </span>
  <span
    class="flex select-button validate"
    [ngClass]="{'selected': validated,
        'not-selected' : canceled}"
    (click)="onValidate()"
  >
    <img src="/assets/img/ic-validate-default.svg" alt="validate" />
  </span>
</div>
