import { Component } from '@angular/core';

import { DialogService } from './dialog.service';
import { DialogInfo } from './dialog-info';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  private static readonly DEFAULTS: DialogInfo = {
    title: '',
    content: '',
    okButtonLabel: 'Ok',
    cancelButtonLabel: 'Annuler',
    showOk: true,
    showCancel: false,
    respOk: true,
    respCancel: false,
    width: 400,
    checkboxLabel: '',
    checkboxOk: true,
  };

  dialogInfo: DialogInfo;
  isDialogVisible: boolean;
  showHeader: boolean;

  constructor(private dialogService: DialogService) {
    this.dialogInfo = { ...DialogComponent.DEFAULTS };
    dialogService.dialogShow$.subscribe(info => {
      this.dialogInfo = { ...DialogComponent.DEFAULTS, ...info };
      this.isDialogVisible = true;
      this.showHeader = !this.dialogInfo.title ? false : true;
      this.dialogInfo.checkboxOk = !this.dialogInfo.checkboxLabel ? false : true;
    });
    dialogService.dialogHide$.subscribe(() => {
      this.dialogInfo = { ...DialogComponent.DEFAULTS };
      this.isDialogVisible = false;
    });
  }

  ok() {
    const reponse = this.dialogInfo.checkboxOk ? 'okWithOptimizedMiquai2TMV' : this.dialogInfo.respOk;
    this.dialogService.sendResponseFromDialog(reponse);
    this.dialogService.hide();
  }

  cancel() {
    this.dialogService.sendResponseFromDialog(this.dialogInfo.respCancel);
    this.dialogService.hide();
  }
}
