import { Component, Input, ChangeDetectionStrategy, OnChanges, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { toUpperCaseFirstChar } from '@app/utils';

@Component({
  selector: 'app-date-nav',
  templateUrl: './date-nav.component.html',
  styleUrls: ['./date-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateNavComponent implements OnChanges {
  @Input() currentYear: number;
  @Input() currentMonth: number;

  @Output() dateChanged = new EventEmitter<string>();

  currentDate: moment.Moment; // to facilitate next/prev date calculations

  months: string[] = moment.months();
  monthMenuItems: MenuItem[];
  yearMenuItems: MenuItem[];

  ngOnChanges() {
    // Whenever the current year or month changes (from @Inputs),
    // we need to regenerate the dropdowns.
    this.initDropdowns();
  }

  previousMonth() {
    this.currentDate.subtract(1, 'month');
    this.dateChanged.emit(this.currentDate.format('YYYY-M'));
  }

  nextMonth() {
    this.currentDate.add(1, 'month');
    this.dateChanged.emit(this.currentDate.format('YYYY-M'));
  }

  //

  /**
   * The dropdowns must be recomputed whenever the current month and/or year change,
   * on order to refresh the link targets and "active" items in PrimeNG menu items.
   */
  private initDropdowns() {
    // Months
    this.monthMenuItems = this.months.map((month, i) => ({
      label: toUpperCaseFirstChar(month),
      styleClass: i + 1 === this.currentMonth ? 'active' : '',
      command: () => this.dateChanged.emit(`${this.currentYear}-${i + 1}`),
    }));
    // Years
    const years: number[] = _.range(this.currentYear - 1, this.currentYear + 6);
    this.yearMenuItems = years.map(year => ({
      label: `${year}`,
      styleClass: year === this.currentYear ? 'active' : '',
      command: () => this.dateChanged.emit(`${year}-${this.currentMonth}`),
    }));
    // Create a date within the current year and month
    this.currentDate = moment(new Date(this.currentYear, this.currentMonth - 1));
  }
}
