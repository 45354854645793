import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import * as moment from 'moment';
import * as _ from 'lodash';
import { rotatedAnimation } from '@app/animations/animation';

// TODO 23/08/2021 Complete with month to fusion with the date nav used for travaux
@Component({
  selector: 'app-conception-date-nav',
  templateUrl: './conception-date-nav.component.html',
  styleUrls: ['./conception-date-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotatedAnimation],
})
export class DateNavConceptionComponent implements OnChanges {
  @Input() selectedYear: number;
  @Input() currentMonth: number;

  @Input() yearMenuItems: MenuItem[];

  @Input() YEAR_SUBSTRACT = 1;
  @Input() YEAR_ADD = 6;

  @Output() dateChanged = new EventEmitter<string>();

  currentDate: moment.Moment; // to facilitate next/prev date calculations

  months: string[] = moment.months();
  monthMenuItems: MenuItem[];

  currentYearLabel: string;

  isDisabledPrev = false;
  isDisabledNext = false;

  toggleYear = false;

  ngOnChanges() {
    // Whenever the current year or month changes (from @Inputs),
    // we need to regenerate the dropdowns.
    if (this.yearMenuItems && this.yearMenuItems.length > 0) {
      this.initDropdowns();
    }
  }

  previous() {
    if (this.currentMonth) {
      this.previousMonth();
    } else {
      this.previousYear();
    }
    this.toggleYear = false;
  }

  next() {
    if (this.currentMonth) {
      this.nextMonth();
    } else {
      this.nextYear();
    }
    this.toggleYear = false;
  }

  onHide() {
    this.toggleYear = false;
  }

  //

  private previousMonth() {
    this.currentDate.subtract(1, 'month');
    this.dateChanged.emit(this.currentDate.format('YYYY-M'));
  }

  private nextMonth() {
    this.currentDate.add(1, 'month');
    this.dateChanged.emit(this.currentDate.format('YYYY-M'));
  }

  private previousYear() {
    this.currentDate.subtract(1, 'year');
    this.dateChanged.emit(this.currentDate.format('YYYY'));
  }

  private nextYear() {
    this.currentDate.add(1, 'year');
    this.dateChanged.emit(this.currentDate.format('YYYY'));
  }

  private yearItemCommand(year: number) {
    this.currentMonth ? this.dateChanged.emit(`${year}-${this.currentMonth}`) : this.dateChanged.emit(`${year}`);
    this.toggleYear = false;
  }

  /**
   * The dropdowns must be recomputed whenever the current month and/or year change,
   * on order to refresh the link targets and "active" items in PrimeNG menu items.
   */
  private initDropdowns() {
    // Months
    if (this.currentMonth) {
      this.monthMenuItems = this.months.map((month, i) => ({
        label: month,
        styleClass: i + 1 === this.currentMonth ? 'active' : '',
        command: () => this.dateChanged.emit(`${this.selectedYear}-${i + 1}`),
      }));
    }

    // Years
    if (this.selectedYear) {
      if (!this.yearMenuItems) {
        const years: number[] = _.range(this.selectedYear - this.YEAR_SUBSTRACT, this.selectedYear + this.YEAR_ADD);
        this.yearMenuItems = years.map(year => ({
          label: `${year}`,
          styleClass: year === this.selectedYear ? 'active' : '',
          command: () => this.yearItemCommand(year),
        }));
      } else {
        this.yearMenuItems = this.yearMenuItems.map(menuItem => ({
          label: menuItem.label,
          styleClass: menuItem.queryParams.year === this.selectedYear ? 'active' : '',
          queryParams: menuItem.queryParams,
          command: () => this.yearItemCommand(menuItem.queryParams.year),
        }));
      }

      let index: number;
      const currentYearItem = this.yearMenuItems.find((menuItem, i) => {
        index = i;
        return menuItem.styleClass === 'active';
      });

      this.currentYearLabel = currentYearItem ? currentYearItem.label : this.yearMenuItems[0].label;

      if (index === 0) {
        this.isDisabledPrev = true;
      } else {
        this.isDisabledPrev = false;
      }

      if (index === this.yearMenuItems.length - 1) {
        this.isDisabledNext = true;
      } else {
        this.isDisabledNext = false;
      }
    }

    // Create a date within the current year and month
    const date = new Date();
    if (this.selectedYear) {
      date.setFullYear(this.selectedYear);
    }

    if (this.currentMonth) {
      date.setMonth(this.currentMonth - 1);
    }

    this.currentDate = moment(date);
  }
}
