<button
  type="button"
  [ngClass]="!isGovSynchronizedHouat && !isGovSynchronizedPasseMinuit ? 'btn-icon' : 'btn-normal'"
  [class.synchronized]="isGovSynchronizedHouat || isGovSynchronizedPasseMinuit"
  [disabled]="isGovNotEditable"
  (click)="menu.toggle($event)"
  pTooltip="Synchroniser le plan de transport"
  tooltipPosition="bottom"
>
  <img src="/assets/img/ic-synchronize-tp.svg" alt="Synchronisation plan de transport" />
  <span *ngIf="isGovSynchronizedHouat">HOUAT</span>
  <span *ngIf="isGovSynchronizedPasseMinuit">Passe-minuit</span>
</button>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
