import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateModel } from '@app/gov-ui/models/date-model';
import Holidays, { HolidaysTypes } from 'date-holidays';
import * as moment from 'moment';
import { DATE_FORMATTERS, isAfterPublicHoliday, isPublicHoliday, WEEK_DAYS2, initPublicHolidays as getPublicHolidays } from '../date-utils';
import { WeekDay } from '../week-day';

/**
 * Custom calendar component
 */
@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCalendarComponent implements OnInit, AfterViewInit {
  @Input() startDate: moment.Moment;
  @Input() endDate: moment.Moment;
  @Input() showTitle = true;
  @Input() showActions = true;
  @Input() showResetCalendar = true;
  @Input() selectedDates: moment.Moment[];
  @Input() selectedDays: WeekDay[];
  @Input() disableDatesSelection = false;
  @Input() holidays: HolidaysTypes.Holiday[] = [];

  @Output() selectDates = new EventEmitter<moment.Moment[]>();
  @Output() selectGroupDay = new EventEmitter<string>();
  @Output() resetCalendar = new EventEmitter<void>();

  /** The map containing all dates from the start to the end date of the calendar */
  mapDates: Map<string, DateModel[]> = new Map<string, DateModel[]>();

  /** Used for displaying */
  NUMBER_OF_DAYS = [];

  /** Selected dates on calendar */
  selectedDatesModel: Set<DateModel> = new Set();

  weekDays = WEEK_DAYS2;

  constructor() {}

  ngOnInit(): void {
    if (!this.startDate && !this.endDate) {
      throw new Error('Il manque la date de début et/ou la date de fin pour créer le calendrier.');
    }

    if (!this.holidays || this.holidays.length === 0) {
      const hd = new Holidays();
      hd.init('FR');
      this.holidays = getPublicHolidays(hd, this.startDate, this.endDate);
    }

    // Fill array of number of days
    for (let i = 1; i <= 31; i++) {
      this.NUMBER_OF_DAYS.push(i);
    }

    this.initCalendar();
  }

  ngAfterViewInit() {
    // Update checkboxes
    if (this.selectedDays && this.selectedDays.length > 0) {
      const checkboxes = document.getElementsByClassName('day-checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i] as HTMLInputElement;
        if (this.selectedDays.find(day => day === checkbox.id)) {
          checkbox.checked = true;
        }
      }
    }
  }

  /**
   * Initialize calendar
   */
  initCalendar() {
    // Fill the calendar with startDate and endDate
    let incrementDate = moment(this.startDate).clone().startOf('month');
    const endCalendar = moment(this.endDate).endOf('month');

    while (incrementDate.diff(endCalendar) <= 0) {
      const dateModel = new DateModel();

      const key = incrementDate.format(DATE_FORMATTERS.MMMM) + ' ' + incrementDate.format(DATE_FORMATTERS.YYYY);

      // Disable date
      if (incrementDate.toDate() < this.startDate.toDate() || incrementDate.toDate() > this.endDate.toDate()) {
        dateModel.isDisabled = true;
      }

      // Holiday date
      dateModel.isPublicHoliday = isPublicHoliday(incrementDate.clone().format(DATE_FORMATTERS.DATE_TIME_2), this.holidays);

      // Before holiday date
      dateModel.isAfterPublicHoliday = isAfterPublicHoliday(incrementDate, this.holidays);

      // Select date
      if (this.selectedDates && this.isSelectedDate(incrementDate)) {
        dateModel.isSelected = true;
        this.selectedDatesModel.add(dateModel);
      }

      // Set the date
      dateModel.date = moment(incrementDate);

      // Set the map of dates
      if (this.mapDates.has(key)) {
        const daysList = this.mapDates.get(key);
        daysList.push(dateModel);
      } else {
        this.mapDates.set(key, [dateModel]);
      }

      // Increment the date
      incrementDate = incrementDate.add(1, 'days');
    }
  }

  /**
   * Check if we should select the date
   * @param date The date
   */
  isSelectedDate(date: moment.Moment) {
    return this.selectedDates.find(sd => sd.format(DATE_FORMATTERS.YYYY_MM_DD) === date.format(DATE_FORMATTERS.YYYY_MM_DD));
  }

  /**
   * Calls when we click on a date
   * @param selectedDate The selected date
   */
  onSelectDate(selectedDate: DateModel) {
    if (this.disableDatesSelection || selectedDate.isDisabled) {
      return;
    }

    // We update select dates list
    if (this.selectedDatesModel.has(selectedDate)) {
      this.selectedDatesModel.delete(selectedDate);
    } else {
      this.selectedDatesModel.add(selectedDate);
    }

    // Then we update the date selection state in the map of dates
    this.mapDates.forEach(value => {
      const date = value.find(d => d === selectedDate);
      if (date) {
        date.isSelected = !date.isSelected;
      }
    });

    this.selectDates.emit(this.getSelectDates());
  }

  /**
   * Allows to select many days corresponding to the day group checked
   * @param event The event
   * @param selectedGroupDay The selected group day
   */
  onToggleGroupDays(event: any, selectedGroupDay: string) {
    if (event.target.checked) {
      // We update only when we check
      this.mapDates.forEach(value => {
        value.forEach(d => {
          if (this.checkDay(selectedGroupDay, d)) {
            if (!d.isDisabled) {
              d.isSelected = true;
              this.selectedDatesModel.add(d);
            }
          }
        });
      });

      this.selectDates.emit(this.getSelectDates());
    }

    this.selectGroupDay.emit(selectedGroupDay);
  }

  /**
   * Reset checkboxes status and the calendar dates selected
   */
  onResetCalendar() {
    // Reset the checkboxes
    const checkboxList = document.getElementsByClassName('day-checkbox');
    for (const element of Array.from(checkboxList)) {
      element['checked'] = false;
    }

    // Reset the dates selected status
    this.mapDates.forEach(value => {
      value.forEach(d => {
        d.isSelected = false;
      });
    });

    // Reset the list of selected dates
    this.selectedDatesModel.clear();
    this.resetCalendar.emit();
  }

  /**
   * To avoid keyvalue pipe default sort
   */
  returnZero() {
    return 0;
  }

  /**
   * Get the selected dates from dateModel list
   * @returns The selected dates
   */
  private getSelectDates(): moment.Moment[] {
    const selectedDates: moment.Moment[] = [];
    this.selectedDatesModel.forEach(selectDate => selectedDates.push(selectDate.date));
    return selectedDates;
  }

  /**
   * Check if a date corresponds to the selectedDay
   * @param selectedGroupDay Lundi, Mardi, etc....
   * @param d The date
   * @returns True if it's matching otherwise False
   */
  private checkDay(selectedGroupDay: string, d: DateModel): boolean {
    const day = d.date.format('dddd');

    if (day === 'lundi' && selectedGroupDay === 'Lu') {
      return true;
    } else if (day === 'mardi' && selectedGroupDay === 'Ma') {
      return true;
    } else if (day === 'mercredi' && selectedGroupDay === 'Me') {
      return true;
    } else if (day === 'jeudi' && selectedGroupDay === 'Je') {
      return true;
    } else if (day === 'vendredi' && selectedGroupDay === 'Ve') {
      return true;
    } else if (day === 'samedi' && selectedGroupDay === 'Sa') {
      return true;
    } else if ((day === 'dimanche' || d.isPublicHoliday) && selectedGroupDay === 'DF') {
      return true;
    } else if (d.isAfterPublicHoliday && selectedGroupDay === 'LF') {
      return true;
    }

    return false;
  }
}
