<button
  type="button"
  class="btn-icon"
  (click)="clickOptimizeTp()"
  [disabled]="isGovNotEditable"
  pTooltip="Optimisation générale"
  tooltipPosition="bottom"
>
  <img src="/assets/img/ic-optimize-tp.svg" alt="Optimisation générale" />
</button>
