<div (focusout)="onFocusOut()" tabindex="0">
  <div class="multiselect-input" tabindex="-1" (click)="onClickInput()" [class.is-disabled]="isDisabled">
    <ng-container *ngIf="itemsSelected.length; else empty">
      <span *ngIf="itemsSelected.length !== items.length; else all">
        <ng-container *ngFor="let item of itemsSelected; let last = last">
          {{ item.label }}<ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
    </ng-container>
    <ng-template #empty><span class="placeholder">{{ placeholder }}</span></ng-template>
    <ng-template #all><span>{{ labelAll }}</span></ng-template>
  </div>

  <div *ngIf="form && !isDisabled" [formGroup]="form" class="multiselect-content">
    <div
      appMultiselectKeyboard
      class="multiselect-content__list"
      (focusin)="onFocusInField()"
      tabindex="-1"
      [class.is-up]="positionList === 'up'"
      [class.is-hidden]="!isShowList"
      [items]="items"
    >
      <ng-container *ngIf="items.length; else nodatas">
        <ul *ngIf="hasSelectAll">
          <li>
            <label class="cbrb-contain" for="{{ id }}-all">
              <input
                type="checkbox"
                id="{{ id }}-all"
                (change)="onToggleSelectAll($event)"
                [checked]="itemsSelected.length === items.length"
              />
              <strong>Tout sélectionner</strong>
              <div class="cbrb-input"></div>
            </label>
          </li>
        </ul>
        <ul [attr.data-type]="'results'" [class.has-icons]="hasIcons">
          <li *ngFor="let item of items; let i = index">
            <label class="cbrb-contain" for="{{ id }}-{{ i }}">
              <input type="checkbox" formControlName="{{ item.value }}" id="{{ id }}-{{ i }}" (change)="onChangeCheckbox($event, item)" />
              {{ item.label }} <img *ngIf="item.srcImg" [src]="item.srcImg" [alt]="item.label" />
              <div class="cbrb-input"></div>
              <div *ngIf="item.nb !== undefined" class="number">{{ item.nb }}</div>
            </label>
          </li>
        </ul>
        <div class="bottom" *ngIf="hasBtnClose">
          <button type="button" class="btn btn-cancel" (click)="onHideList()">Fermer</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nodatas>
  <div class="nodatas">Aucune donnée.</div>
</ng-template>
