/**
 * @file
 * Reusable presets for application Dialogs.
 *
 * This lets us reuse consistent dialogs across the app.
 */
import { DialogInfo } from './dialog-info';

export const DIALOG_PRESETS: Map<string, DialogInfo> = new Map([
  //
  // ----- Equilibre Form -----
  //

  [
    'EQFORM__WARN_COULD_LOSE_CHANGES',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel équilibre. 
              Souhaitez-vous continuer ?`,
      okButtonLabel: 'Oui',
      showCancel: true,
    },
  ],
  [
    'EQFORM__PROMPT_SAVE_BEFORE_LEAVING',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel équilibre. 
              Souhaitez-vous les enregistrer avant de continuer ?`,
      okButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Ne pas enregistrer',
      respOk: 'SAVE',
      respCancel: 'DO_NOT_SAVE',
    },
  ],
  [
    'EQFORM__PROMPT_SAVE_BEFORE_LEAVING_BUT_EQFORM_HAS_ERRORS',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel équilibre. 
              Souhaitez-vous les enregistrer avant de continuer ?`,
      okButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Quitter sans enregistrer',
      respOk: 'CANNOT_SAVE_BECAUSE_EQFORM_HAS_ERRORS', // this response differs from the form with no errors
      respCancel: 'DO_NOT_SAVE',
    },
  ],
  [
    'EQFORM__CANT_SAVE_BECAUSE_ERRORS',
    {
      title: 'Erreur',
      content: `Impossible d'enregistrer car le formulaire contient des erreurs. 
              Veuillez corriger ces erreurs pour pouvoir enregistrer.`,
    },
  ],

  //
  // ----- Import PDT -----
  //
  [
    'IMPORT_PDT__CONFIRM_IMPORT_HOUAT',
    {
      title: 'Attention',
      content: `Le flux de récupération des données de roulement n'est pas encore stable. 
              En cas d'erreur, attendez 30 minutes avant de réessayer. En cas de nouvel échec, veuillez contacter le support OpenGOV.`,
      okButtonLabel: 'Continuer',
    },
  ],
  [
    'IMPORT_PDT__CONFIRM_IMPORT_HOUAT_ONLY',
    {
      title: 'Attention',
      content: `La récupération des données Houat peut durer plusieurs dizaines de secondes. 
              En cas d'erreur, attendez 30 minutes avant de réessayer. En cas de nouvel échec, veuillez contacter le support OpenGOV.`,
      okButtonLabel: 'Continuer',
    },
  ],
  [
    'IMPORT_PDT__CONFIRM_IMPORT_F2K',
    {
      content: `Souhaitez-vous importer un fichier 2000 ?`,
      cancelButtonLabel: 'Non',
      okButtonLabel: 'Oui, importer',
    },
  ],

  //
  // ----- Certification -----
  //

  [
    'CERTIF__CONFIRM_CERTIFY',
    {
      content: `Êtes-vous sûr·e de vouloir certifier ce GOV ? 
              Une fois le GOV chargé par le destinataire OpenGOV Temps Réel, vous ne pourrez plus le modifier.`,
      showCancel: true,
      okButtonLabel: 'Valider la certification',
      okButtonClass: 'green', // green background
    },
  ],
  [
    'CERTIF__CONFIRM_UNCERTIFY',
    {
      content: `Êtes-vous sûr·e de vouloir annuler la certification ? 
              Vous devrez de nouveau certifier ce GOV pour que le destinataire OpenGOV Temps Réel puisse y accéder.`,
      cancelButtonLabel: 'Fermer',
      okButtonLabel: 'Annuler la certification',
      okButtonClass: 'red', // red background
    },
  ],
  [
    'CERTIF__CANT_CERTIFY_BECAUSE_OGTR',
    {
      content: 'Impossible de valider la certification. Un GOV a déjà été chargé dans OpenGOV Temps Réel à cette date.',
    },
  ],
  [
    'CERTIF__CANT_UNCERTIFY_BECAUSE_OGTR',
    {
      content: `Vous ne pouvez pas annuler la certification de ce GOV, car le destinataire OpenGOV Temps Réel l'a déjà importé.`,
    },
  ],
  [
    'CERTIF__CANT_CERTIFY_BECAUSE_GOV_IS_DIRTY',
    {
      content: `Une ou plusieurs modifications sont en cours sur le GOV. 
              Veuillez la (les) finaliser avant de demander à nouveau la certification.`,
    },
  ],
  [
    'CERTIF__CANT_CERTIFY_DATE_CURRENT_OR_PAST',
    {
      content: 'Vous ne pouvez pas certifier une journée passée ni la journée en cours.',
    },
  ],
  [
    'CERTIF__CANT_UNCERTIFY_DATE_CURRENT_OR_PAST',
    {
      content: `Vous ne pouvez pas annuler la certification d'une journée passée ni de la journée en cours.`,
    },
  ],

  //
  // ----- Validate a journee type -----
  //

  [
    'JT__CONFIRM_VALIDATE',
    {
      content: `Êtes-vous sûr·e de vouloir valider cette journée type ? 
              Elle sera alors proposée dans le module Adaptation.`,
      showCancel: true,
      okButtonLabel: 'Valider la journée type',
      okButtonClass: 'green', // green background
    },
  ],

  [
    'JT__CONFIRM_UNVALIDATE',
    {
      content: `Êtes-vous sûr·e de vouloir annuler la validation de cette journée type ? Celle-ci ne sera plus disponible dans le module Adaptation.`,
      showCancel: true,
      okButtonLabel: 'Annuler la validation',
      okButtonClass: 'red', // red background
    },
  ],
  [
    'JT__CANT_VALIDATE_BECAUSE_GOV_IS_DIRTY',
    {
      content: `Une ou plusieurs modifications sont en cours sur le GOV. 
              Veuillez la (les) finaliser avant de valider la journée type.`,
    },
  ],

  //
  // ----- Apply a journee type -----
  //

  [
    'JT__CANT_APPLY_BECAUSE_GOV_IS_DIRTY',
    {
      content:
        "Des modifications sont en cours sur ce plan de transport. Veuillez les valider ou les annuler avant d'appliquer une journée type.",
    },
  ],
  [
    'JT__CANT_APPLY_BECAUSE_GOV_IS_CERTIFIED',
    {
      content: 'On ne peut pas appliquer de journée type à un GOV certifié.',
    },
  ],

  [
    'JT__CONFIRM_APPLY',
    {
      content: `Êtes-vous sûr·e de vouloir appliquer cette journée type ?
      Tous les statuts de conflits non traités risquent d'être remplacés par ceux de la journée type.`,
      showCancel: true,
      okButtonLabel: 'Appliquer la journée type',
      okButtonClass: 'blue', // blue background
    },
  ],

  //
  // ----- Synchronize PDT -----
  //

  [
    'SYNCHRONIZE__CANT_SYNCHRONIZE_BECAUSE_GOV_IS_DIRTY',
    {
      content: `Une ou plusieurs modifications sont en cours sur le GOV. 
              Veuillez la (les) finaliser avant de demander à nouveau la synchronisation.`,
    },
  ],

  [
    'SYNCHRONIZE_PM__NO_DIFFERENCES',
    {
      content: `Il n'y a actuellement aucune différence entre les données Passe-minuit et le GOV.`,
    },
  ],

  [
    'SYNCHRONIZE_HOUAT__NO_DIFFERENCES',
    {
      content: `Il n'y a actuellement aucune différence entre les données HOUAT et le GOV.`,
    },
  ],

  [
    'SYNCHRONIZE__CONFIRM_SYNCHRONIZE',
    {
      content: `Voulez-vous lancer la synchronisation ? 
              Vous pourrez arbitrer les modifications que vous souhaitez apporter au plan de transport.`,
      okButtonLabel: 'Lancer la synchronisation',
      showCancel: true,
    },
  ],

  [
    'SYNCHRONIZE__RESET_SYNCHRONIZE',
    {
      content: 'Voulez-vous vraiment réinitialiser la synchronisation du plan de transport sans enregistrer les modifications ?',
      okButtonLabel: 'Réinitialiser la synchronisation',
      okButtonClass: 'red',
      showCancel: true,
      width: 430, // In order to have buttons aligned
    },
  ],

  [
    'SYNCHRONIZE__LEAVE_SYNCHRONIZE',
    {
      content: 'Voulez-vous vraiment quitter la synchronisation du plan de transport sans enregistrer les modifications ?',
      okButtonLabel: 'Quitter la synchronisation',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],

  [
    'SYNCHRONIZE__VALIDATE_SYNCHRONIZE_FOR_2TMV',
    {
      content: 'Voulez-vous vraiment valider la synchronisation du plan de transport ?',
      okButtonLabel: 'Valider la synchronisation',
      showCancel: true,
      checkboxLabel: 'Optimiser aussi les mi-quais 2TMV des passe-minuit J+1',
      okButtonClass: 'green',
    },
  ],

  [
    'SYNCHRONIZE__VALIDATE_SYNCHRONIZE',
    {
      content: 'Voulez-vous vraiment valider la synchronisation du plan de transport ?',
      okButtonLabel: 'Valider la synchronisation',
      showCancel: true,
    },
  ],

  //
  // ----- Compare PDT -----
  //

  [
    'COMPARE_PDT__REJECT_ALL',
    {
      content: 'Voulez-vous vraiment refuser toutes les propositions de modification de cette liste ?',
      okButtonLabel: 'Oui, refuser toute la liste',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],

  [
    'COMPARE_PDT__ACCEPT_ALL',
    {
      content: 'Voulez-vous vraiment approuver toutes les propositions de modification de cette liste ?',
      okButtonLabel: 'Oui, approuver toute la liste',
      showCancel: true,
      width: 430, // In order to have buttons aligned
    },
  ],

  [
    'COMPARE_PDT__CANCEL_REJECT_OR_ACCEPT_ALL',
    {
      content: 'Voulez-vous vraiment réinitialiser toutes les propositions de modification de cette liste ?',
      okButtonLabel: 'Oui, réinitialiser la liste',
      showCancel: true,
    },
  ],

  //
  // ----- Travaux -----
  //

  [
    'TRAVAUX__CONFIRM_DELETE_OP',
    {
      content: 'Êtes-vous sûr·e de vouloir supprimer cette opération travaux ?',
      okButtonLabel: 'Supprimer',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],
  [
    'TRAVAUX__CONFIRM_DELETE_SELECTED_OPS',
    {
      content: 'Êtes-vous sûr·e de vouloir supprimer les opérations travaux sélectionnées ?',
      okButtonLabel: 'Supprimer',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],

  [
    'TRAVAUX__CONFIRM_DELETE_INDISPO_ITI',
    {
      content: "Êtes-vous sûr·e de vouloir supprimer cette indisponibilité d'itinéraire ?",
      okButtonLabel: 'Supprimer',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],
  [
    'TRAVAUX__CONFIRM_DELETE_SELECTED_INDISPOS_ITIS',
    {
      content: "Êtes-vous sûr·e de vouloir supprimer ces indisponibilités d'itinéraires sélectionnées ?",
      okButtonLabel: 'Supprimer',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],
  [
    'TVXFORM__WARN_COULD_LOSE_CHANGES',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel travaux. 
              Souhaitez-vous continuer ?`,
      okButtonLabel: 'Oui',
      showCancel: true,
    },
  ],
  [
    'TVXFORM__PROMPT_SAVE_BEFORE_LEAVING',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel travaux. 
              Souhaitez-vous les enregistrer avant de continuer ?`,
      okButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Ne pas enregistrer',
      respOk: 'SAVE',
      respCancel: 'DO_NOT_SAVE',
    },
  ],
  [
    'TVXFORM__PROMPT_SAVE_BEFORE_LEAVING_BUT_TVXFORM_HAS_ERRORS',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours sur le panel travaux. 
              Souhaitez-vous les enregistrer avant de continuer ?`,
      okButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Quitter sans enregistrer',
      respOk: 'CANNOT_SAVE_BECAUSE_TVXFORM_HAS_ERRORS', // this response differs from the form with no errors
      respCancel: 'DO_NOT_SAVE',
    },
  ],
  [
    'TVXFORM__CANT_SAVE_BECAUSE_ERRORS',
    {
      title: 'Erreur',
      content: `Impossible d'enregistrer car le formulaire contient des erreurs. 
              Veuillez corriger ces erreurs pour pouvoir enregistrer.`,
    },
  ],

  //
  // ----- PDT Messages -----
  //

  [
    'TP__CONFIRM_CANCEL_CHANGES',
    {
      content: `Souhaitez-vous annuler les modifications en cours sur le GOV ?`,
      okButtonLabel: 'Oui, annuler les modifications',
      cancelButtonLabel: 'Non',
      okButtonClass: 'red',
    },
  ],

  //
  // ----- Itineraires View Messages -----
  //

  [
    'ITINERAIRES__WARN_COULD_LOSE_CHANGES',
    {
      title: 'Attention !',
      content: `Vous allez perdre vos modifications en cours. 
              Souhaitez-vous continuer ?`,
      okButtonLabel: 'Oui',
      showCancel: true,
      respOk: 'OK',
    },
  ],

  //
  // ----- Admin Messages -----
  //

  [
    'ADMIN_ERROR__NO_STATION_SELECTED',
    {
      title: 'Erreur',
      content: `Vous devez sélectionner une gare pour effectuer cette opération.`,
    },
  ],

  [
    'ADMIN_ERROR__PARAMETRAGE_FILE_ERROR',
    {
      title: 'Erreur',
      content: `Un des fichiers a été modifié. Veuillez les resélectionner.`,
    },
  ],

  [
    'ADMIN__CONFIRM_DELETE_STATION',
    {
      content: `Etes-vous sûr de vouloir supprimer la gare et toutes les données associées ?
              (La suppression peut prendre quelque temps en fonction du nombre de plans de transport)`,
      okButtonLabel: 'Oui, supprimer',
      okButtonClass: 'red',
      showCancel: true,
    },
  ],

  [
    'ADMIN__CONFIRM_DELETE_STATION_NEWPANEL',
    {
      content: `Voulez-vous vraiment supprimer cette gare et toutes les données associées (plans de transports, utilisateurs etc.) de la base de données d'OpenGOV ?`,
      okButtonLabel: `Supprimer la gare d'OpenGOV`,
      okButtonClass: 'red',
      showCancel: true,
      width: 520, // Sketch
    },
  ],

  [
    'ADMIN__CONFIRM_DELETE_OG_DATABASE_NEWPANEL',
    {
      content: `Voulez-vous vraiment supprimer cet utilisateur de la base de données OpenGOV ? Il disparaîtra de toutes les gares auxquelles il appartenait.`,
      okButtonLabel: `Supprimer l'utilisateur d'OpenGOV`,
      okButtonClass: 'red',
      cancelButtonLabel: 'Annuler',
      respOk: 'delete',
      width: 520, // Sketch
    },
  ],

  [
    'ADMIN__CONFIRM_DELETE_USER_FROM_GARE',
    {
      content: 'Voulez-vous vraiment retirer cet utilisateur de cette gare ?',
      okButtonLabel: "Retirer l'utilisateur de la gare",
      okButtonClass: 'red',
      cancelButtonLabel: 'Annuler',
      respOk: 'delete',
      width: 520, // Sketch
    },
  ],

  [
    'ADMIN_ALERT__PERMISSIONS_NO_SAVE',
    {
      content: `Voulez-vous enregistrer les modifications avant de 
    quitter ?`,
      okButtonLabel: `Enregistrer`,
      cancelButtonLabel: 'Annuler les modifications',
      width: 520, // Sketch
    },
  ],

  //
  // ----- General Messages -----
  //

  [
    'FORM__CANT_SAVE_BECAUSE_ERRORS',
    {
      title: 'Erreur',
      content: `Impossible d'enregistrer car le formulaire contient des erreurs. 
              Veuillez corriger ces erreurs pour pouvoir enregistrer.`,
    },
  ],
]);
