import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { A11yModule } from '@angular/cdk/a11y';

import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';

// Components
import { SelectWithPadlockComponent } from './select-with-padlock/select-with-padlock.component';
import { DropdownModule } from 'primeng/dropdown';
import { DropIndicationComponent } from './drop-indication/drop-indication.component';
import { CustomDataTableComponent } from './custom-data-table/custom-data-table.component';
import { TextPipe } from './text.pipe';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { CustomCalendarComponent } from './custom-calendar/custom-calendar.component';
import { DateNavConceptionComponent } from './conception-date-nav/conception-date-nav.component';
import { WeekDaySortPipe } from './week-day-sort.pipe';
import { DateNavComponent } from './date-nav/date-nav.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { InputFilterComponent } from './input-filter/input-filter.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { MultiselectKeyboardDirective } from './multiselect/multiselect-keyboard.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MenuModule, DragDropModule, A11yModule, FormsModule],
  declarations: [
    SelectWithPadlockComponent,
    DropIndicationComponent,
    CustomDataTableComponent,
    TextPipe,
    AccordionItemComponent,
    CustomCalendarComponent,
    DateNavConceptionComponent,
    WeekDaySortPipe,
    DateNavComponent,
    BackButtonComponent,
    ColorPaletteComponent,
    SelectButtonComponent,
    InputFilterComponent,
    MultiselectComponent,
    MultiselectKeyboardDirective,
  ],
  exports: [
    // Exported modules
    CommonModule,
    RouterModule, // so that [routerLink] directive can be used
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    A11yModule,
    ButtonModule, // Buttons might be used anywhere
    TooltipModule, // Tooltips might be used anywhere
    MenuModule,
    FileUploadModule, // Used in multiple places
    CalendarModule, // Used in `TrainFieldComponent`, `TravauxSearchFormComponent`
    MultiSelectModule, // Used in `TravauxOpFormComponent`
    DropdownModule,
    // Exported components
    SelectWithPadlockComponent,
    DropIndicationComponent,
    TextPipe,
    CustomDataTableComponent,
    AutoCompleteModule,
    AccordionItemComponent,
    CustomCalendarComponent,
    DateNavConceptionComponent,
    WeekDaySortPipe,
    DateNavComponent,
    BackButtonComponent,
    ColorPaletteComponent,
    SelectButtonModule,
    SelectButtonComponent,
    InputFilterComponent,
    MultiselectComponent,
    MultiselectKeyboardDirective,
  ],
})
export class SharedModule {}
