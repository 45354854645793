/**
 * @file
 * Station model, along with related models (e.g. Infrastructure...)
 */
import { TypeCirculation, TypeMateriel, GovColorMode, TypeVoie, TypeCategorie } from './transportation-plan';
import { ConflictType } from './conflict-type';
import { TypeLabelOrigDest } from './misc-models';
import * as _ from 'lodash';

export interface Station {
  id: number;
  nom: string;
  code: string;
  couleurGovTraitRef: GovColorMode.TYPE_MATERIEL | GovColorMode.TYPE_CIRCULATION;
  couleurGovNumeroTrainRef: GovColorMode;
  afficherAmorce: boolean;
  origineDestEquilInfoType: TypeLabelOrigDest;
  afficherTypeMateriel: boolean;
  typeModelisation: TypeModelisation; // Which type of itinerary should be displayed in the eqForm: MACRO, MICRO
  modeAttribItin: ModeAttribItin;

  /* The props below are populated from additional endpoints, different from `/api/gares` */
  infrastructure: Infrastructure;
  typesCirculation: TypeCirculation[];
  typesMateriel: TypeMateriel[];
  typesConflit: ConflictType[];
  typesCategories: TypeCategorie[];
  // TODO 28/04/2023 Move to infra when it will be managed by the backend
  infraSchema: any;
}

export enum TypeModelisation {
  MACRO = 'MACRO',
  MICRO = 'MICRO',
  NONE = 'NONE', // NB. frontend only; used as default value
}

export enum ModeAttribItin {
  PRIVILEGIE = 'PRIVILEGIE',
  OPTIMISE = 'OPTIMISE',
  NONE = 'NONE', // NB. frontend only; used as default value
}

export interface Infrastructure {
  id: number;
  codeGare: string;
  listVoieAQuai: VoieAQuai[];
  listItineraire: Itineraire[];
  listVoieEnLigne: VoieEnLigne[];
  listPRGare: PRGare[];
  listVoieAvantGare?: VoieAvantGare[];
  parametrageId: number;
}

export interface Parametrage {
  id: number;
  codeGare: string;
  libelle: string;
  creationDate: string;
  dateDebut: string;
  dateFin?: string;
  dateApplication: string;
  annualService: string;
  periods: string[];
  infraFileName: string;
  exploitFileName: string;
  infrastructure?: Infrastructure;

  // UI parameters
  highlight?: boolean;
}
export interface VoieAQuai {
  id: number;
  nom: string;
  positionGOV: number;
  direction1: string;
  longueurDirection1: number | undefined;
  direction2: string;
  longueurDirection2: number | undefined;
  fictive: boolean; // New Sep 2020
  subType: string;
  cich: string;
  miQuais: MiQuai[];

  // Not used ??
  relationQuai: any;
  numeroQuai: any;
  quaiCommun: boolean;

  // NB. Frontend-specific prop
  position: number;
}

/**
 * Subtype enum
 */
export enum VoieSubType {
  IGNORE = 'IGNORE',
  ETUDE = 'ETUDE',
  FICTIVE = 'FICTIVE',
  MANOEUVRE = 'MANOEUVRE',
}

export interface MiQuai {
  nom: string;
  /** Parent */
  voieAQuai: string;
  direction: string;
  positionRelativeGOV: string;

  direction1: string;
  longueurDirection1: number;
  direction2: string;
  longueurDirection2: number;
}

export enum PositionRelativeGOV {
  DESSUS = 'DESSUS',
  DESSOUS = 'DESSOUS',
}

/**
 * Lightweight VAQ info used in GOV chart
 * (we don't need to full VAQ info to draw the chart)
 */
export interface VaqInfo {
  nom: string;

  // The props below are used in ChartPlatforms
  vaqIndex?: number;
  direction1?: string;
  direction2?: string;
  longueurDirection1?: number;
  longueurDirection2?: number;
  quaiCommun?: boolean;
  fictive?: boolean;
  /** Positions that a Vaq can have (2 if it has miquais, 1 otherwise) */
  positions: number[];

  cich?: string;
  subType?: string;
  miQuais?: MiQuaiInfo[];
  /** Filtered value used to know if a Vaq is visible or not */
  visible?: boolean;
}

export interface MiQuaiInfo {
  nom: string;
  /** Parent */
  voieAQuai: string;
  direction: string;
  positionRelativeGOV: string;

  direction1: string;
  longueurDirection1: number;
  direction2: string;
  longueurDirection2: number;

  /** mi quai position based on positionrelativeGOV */
  position: number;
}

export interface Itineraire {
  rid: string;
  composition: string;
  segments: string;
  privilege: number;
  longueurMaximumAutorise: number;
  sensAutorise: string;
  voieDebutNom: string;
  voieDebutType: TypeVoie;
  voieFinNom: string;
  voieFinType: TypeVoie;
  listNomVoieAvantGare: string[];
  direction: string;
  miQuai: boolean;
  compositionDisplayed: string;
}

export interface VoieEnLigne {
  direction: string;
  id: number;
  isArriveAutorise: boolean;
  isDepartAutorise: boolean;
  longueurSAS: string;
  nom: string;
  ordre: number;
  couleur: string;
  fictive: boolean; // New Sep 2020
}

export interface VoieAvantGare extends Voie {
  direction: string;
  niveau: number;
}

export interface Voie {
  id: number;
  nom: string;
  ordre: number;
  subType: string;
  fictive: boolean;
}

export enum Sens {
  DEPART = 'DEPART',
  ARRIVEE = 'ARRIVEE',
}

export interface CodeChantier {
  tr3: string;
}

export interface Localite {
  tr3a: string;
  libelle: string;
  libelleCourtChantier: string;
  cich: string;
}

export interface PRGare {
  cich: string;
  type: string;
  tr3a?: string;
}

/**
 * Allows to know the number of voies counting the mi-quais
 * @param vaqList The vaqList
 * @returns the number of voies
 */
export function computeTotalVaqWithMiQuai(vaqList: VaqInfo[]) {
  return vaqList.length + vaqList.filter(vaq => hasVaqMiQuais(vaq))?.length;
}

/**
 *
 * @param vaq The vaq
 * @returns true if it's a vaq with mi-quais false otherwise
 */
export function hasVoieAQuaiMiQuais(vaq: VoieAQuai) {
  return !_.isEmpty(vaq.miQuais);
}

/**
 *
 * @param vaq The vaq
 * @returns true if it's a vaq mi-quais false otherwise
 */
export function hasVaqMiQuais(vaq: VaqInfo) {
  return !_.isEmpty(vaq.miQuais);
}

/**
 * Converts a list of VoieAQuai to a list of VaqInfo
 * @param vaqList The vaqList
 * @returns a list of VaqInfo
 */
export function processVaqListToVaqInfoList(vaqList: VoieAQuai[]): VaqInfo[] {
  let nextPosition = 0;
  return vaqList.map((vaq: VoieAQuai, i: number) => {
    const positions = [];
    positions.push(nextPosition);
    if (hasVoieAQuaiMiQuais(vaq)) {
      nextPosition++;
      positions.push(nextPosition);
    }
    const vaqInfo = {
      nom: vaq.nom,
      vaqIndex: i,
      direction1: vaq.direction1,
      direction2: vaq.direction2,
      longueurDirection1: vaq.longueurDirection1,
      longueurDirection2: vaq.longueurDirection2,
      quaiCommun: vaq.quaiCommun,
      fictive: vaq.fictive,
      subType: vaq.subType,
      cich: vaq.cich,
      miQuais: vaq.miQuais?.map(mq => {
        const miQuai = mq as MiQuaiInfo;
        miQuai.position = mq.positionRelativeGOV === PositionRelativeGOV.DESSUS ? positions[0] : positions[1];
        return miQuai;
      }),
      positions,
      visible: true,
    } as VaqInfo;
    nextPosition++;
    return vaqInfo;
  });
}

/**
 * Update vaqs positions and index
 * @param vaqList The vaq list
 * @returns the list of VaqInfo
 */
export function updateVaqsPositionsAndIndex(vaqList: VaqInfo[]): VaqInfo[] {
  let nextPosition = 0;
  return vaqList.map((vaq: VaqInfo, i: number) => {
    const positions = [];
    positions.push(nextPosition);
    if (hasVaqMiQuais(vaq)) {
      nextPosition++;
      positions.push(nextPosition);
    }
    const vaqInfo = {
      ...vaq,
      vaqIndex: i,
      miQuais: vaq.miQuais?.map(mq => {
        const miQuai = mq as MiQuaiInfo;
        miQuai.position = mq.positionRelativeGOV === PositionRelativeGOV.DESSUS ? positions[0] : positions[1];
        return miQuai;
      }),
      positions,
      visible: true,
    } as VaqInfo;
    nextPosition++;
    return vaqInfo;
  });
}

/**
 * Converts a list of VoieAQuai to a list of VaqInfo
 * @param vaqList The vaqList
 * @param vaqNames Array of vaqs names
 * @returns a list of VaqInfo
 */
export function processVaqListToVaqInfoListWithFilter(vaqList: VoieAQuai[], vaqNames: string[]): VaqInfo[] {
  let nextPosition = 0;
  return vaqList.map((vaq: VoieAQuai, i: number) => {
    const visible = vaqNames === undefined || (vaqNames && vaqNames.includes(vaq.nom));
    const positions = [];

    if (visible) {
      positions.push(nextPosition);
      if (hasVoieAQuaiMiQuais(vaq)) {
        nextPosition++;
        positions.push(nextPosition);
      }
    }

    const vaqInfo = {
      nom: vaq.nom,
      vaqIndex: i,
      direction1: vaq.direction1,
      direction2: vaq.direction2,
      longueurDirection1: vaq.longueurDirection1,
      longueurDirection2: vaq.longueurDirection2,
      quaiCommun: vaq.quaiCommun,
      fictive: vaq.fictive,
      subType: vaq.subType,
      cich: vaq.cich,
      miQuais: vaq.miQuais?.map(mq => {
        const miQuai = mq as MiQuaiInfo;
        miQuai.position = mq.positionRelativeGOV === PositionRelativeGOV.DESSUS ? positions[0] : positions[1];
        return miQuai;
      }),
      positions,
      visible,
    } as VaqInfo;

    if (visible) {
      nextPosition++;
    }

    return vaqInfo;
  });
}

/**
 * Update vaqs positions and index
 * @param vaqList The vaq list
 * @returns the list of VaqInfo
 */
export function updateVaqsPositionsAndIndexWithFilter(vaqList: VaqInfo[], vaqNames: string[]): VaqInfo[] {
  let nextPosition = 0;
  return vaqList.map((vaq: VaqInfo, i: number) => {
    const visible = vaqNames === undefined || (vaqNames && vaqNames.includes(vaq.nom));
    const positions = [];

    if (visible) {
      positions.push(nextPosition);
      if (hasVaqMiQuais(vaq)) {
        nextPosition++;
        positions.push(nextPosition);
      }
    }

    const vaqInfo = {
      ...vaq,
      vaqIndex: i,
      miQuais: vaq.miQuais?.map(mq => {
        const miQuai = mq as MiQuaiInfo;
        miQuai.position = mq.positionRelativeGOV === PositionRelativeGOV.DESSUS ? positions[0] : positions[1];
        return miQuai;
      }),
      positions,
      visible,
    } as VaqInfo;

    if (visible) {
      nextPosition++;
    }

    return vaqInfo;
  });
}
