import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BackendService } from '@app/backend';
import { Parametrage, Station } from '@app/models';
import { User, UserDetailsData, USER_PERMISSION } from '@app/auth';
import { map } from 'rxjs/operators';
import { BaseService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  private stationsSubj = new Subject<Station[]>();
  private parametragesSubj = new Subject<Parametrage[]>();
  private usersByGareSubj = new Subject<User[]>();
  private usersSubj = new Subject<User[]>();
  private userDetailsData = new Subject<UserDetailsData>();

  constructor(private http: BackendService) {
    super();
  }

  getInfo(): Observable<any> {
    return this.http.get('public/info');
  }

  getUsers(): Observable<User[]> {
    return this.http.get('api/users');
  }

  getSelectedUser(): User {
    return this.cache.getSelectedUser();
  }

  /**
   * @returns New list of users as an Observable
   */
  getUsersObs(): Observable<User[]> {
    return this.usersSubj.asObservable();
  }

  /**
   * Listen changes to refresh users list
   * @param users list of users
   */
  updateUsers(users: User[]) {
    this.usersSubj.next(users);
  }

  usersByGare(codeGare: string): Observable<any> {
    return this.http.get(`api/users?code_gare=${codeGare}`); // TODO 6th June 2023 : back should return the creationDate of the user (ticket no. OP-3057)
  }

  /**
   * Listen changes to refresh users list
   * @param users list of users
   */
  updateUsersByGare(users: User[]) {
    this.usersByGareSubj.next(users);
  }

  /**
   * @returns new list of users as an Observable
   */
  getUsersByGareObs(): Observable<User[]> {
    return this.usersByGareSubj.asObservable();
  }

  getStations(): Observable<Station[]> {
    return this.http.get('api/gares');
  }

  /**
   * Listen changes to refresh stations list
   * @param stations list of stations
   */
  updateStations(stations: Station[]) {
    this.stationsSubj.next(stations);
  }

  /**
   *
   * @returns new list of stations as an Observable
   */
  getStationsObs(): Observable<Station[]> {
    return this.stationsSubj.asObservable();
  }

  // TODO 6 Apr 2023 : to be updated when endpoint will be created to get each station individually (for now we use localStorage)
  getSelectedStation(stationCode: string) {
    return this.cache.getStation(stationCode);
  }

  // TODO 2nd June 2023 : to be deleted when endpoint will be created to display the edited data from database
  // (for now we update the database and the localStorage)
  updateSelectedStation(station: Station) {
    return this.cache.setStation(station);
  }

  /**
   * @returns list of permissions
   */
  getPermissions(): Observable<USER_PERMISSION[]> {
    return this.http.get('api/users/available_permissions');
  }

  uploadGareExploitationFile(stationId: string, file: File): Observable<any> {
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(`api/admin/gares/${stationId}`, formdata);
  }

  uploadInfrastructureFile(stationId: string, infrastructureId: string, file: File): Observable<any> {
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(`api/admin/gares/${stationId}/infrastructures/${infrastructureId}`, formdata);
  }

  /**
   * Create/edit the station
   * @param force Allows to protect the endpoint
   * @param station The station
   */
  saveStation(station: Station): Observable<Station> {
    const url = `api/admin/gares`;
    const createRequest$ = this.http.post(url, station);
    const updateRequest$ = this.http.put(url, station);
    const saveRequest$: Observable<Station> = station.id ? updateRequest$ : createRequest$;

    return saveRequest$;
  }

  /**
   * Delete the station
   * @param codeGare The code gare
   * @param force Allows to protect the endpoint
   */
  deleteStation(codeGare: string, force: boolean): Observable<any> {
    return this.http.delete(`api/admin/gares/${codeGare}?force=${force}`);
  }

  /**
   * Download an infrastructure file
   * @param stationId station id
   * @param parametrageId parametrage id
   */
  downloadInfrastructureFile(stationId: string, parametrageId: number): Observable<any> {
    return this.http.get(`api/admin/gares/${stationId}/infrastructures?parametrageId=${parametrageId}&format=xlsx`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  /**
   * Download an exploitation file
   * @param stationId station id
   * @param parametrageId parametrage id
   */
  downloadInfoExploitationFile(stationId: string, parametrageId: number): Observable<any> {
    return this.http.get(`api/admin/gares/${stationId}/exploitation?parametrageId=${parametrageId}&format=xlsx`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  /**
   * Download the parametrage load report file
   * @param stationId station id
   * @param parametrageId parametrage id
   */
  downloadParametrageLoadReportFile(stationId: string, parametrageId: number): Observable<any> {
    return this.http.get(`api/admin/gares/${stationId}/parametrages/report?parametrageId=${parametrageId}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  updateUser(username: string, newPassword?: string) {
    return this.http.patch(`api/users/${username}`, { newPassword });
  }

  deleteUserGarePermission(username: string, codeGare: string) {
    return this.http.delete(`api/users/${username}/garePermissions/${codeGare}`);
  }

  updateUserPermissions(username: string, codeGare: string, permissions?: string[]) {
    return this.http.post(`api/users/${username}/garePermissions`, {
      codeGare: codeGare,
      garePermissions: permissions,
    });
  }

  createUser(username: string, password: string) {
    return this.http.post(`api/users`, {
      username: username,
      newPassword: password,
    });
  }

  getTasks() {
    return this.http.get('api/tasks/all');
  }

  cleanTasks() {
    return this.http.delete('api/tasks/clean');
  }

  /**
   * Get the parametrages from a station
   * @param stationId Code station
   */
  getParametragesByStation(stationId: string): Observable<Parametrage[]> {
    return this.http.get(`api/admin/gares/${stationId}/parametrages`).pipe(map((parametrages: Parametrage[]) => parametrages));
  }

  /**
   * Listen changes to refresh parametrages list
   * @param parametrages list of parametrages
   */
  updateParametrages(parametrages: Parametrage[]) {
    this.parametragesSubj.next(parametrages);
  }

  /**
   * @returns New list of parametrages as an Observable
   */
  getParametragesObs(): Observable<Parametrage[]> {
    return this.parametragesSubj.asObservable();
  }

  /**
   * Create a parametrage
   * @param stationId station code
   * @param infraFile infra file
   * @param exploitFile exploit file
   * @return retour de requête
   */
  createParametrage(stationId: string, infraFile: File, exploitFile: File): Observable<any> {
    const formdata = new FormData();
    formdata.append('infra_file', infraFile);
    formdata.append('exploit_file', exploitFile);
    return this.http.post(`api/admin/gares/${stationId}/parametrages`, formdata);
  }

  /**
   * Delete a parametrage
   * @param stationId station code
   * @param parametrageId parametrage id
   * @return retour de requête
   */
  deleteParametrage(stationId: string, parametrageId: number): Observable<any> {
    return this.http.delete(`api/admin/gares/${stationId}/parametrages/${parametrageId}?force=true`);
  }

  getUserDetailsDataObs(): Observable<UserDetailsData> {
    return this.userDetailsData.asObservable();
  }

  setUserDetailsData(userDetailsData: UserDetailsData) {
    this.userDetailsData.next(userDetailsData);
  }
}
