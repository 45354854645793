/**
 * @file
 * Local application cache (uses localStorage).
 */
import { Inject, Injectable } from '@angular/core';

import { StorageService } from 'ngx-webstorage-service';

import { Observable, of } from 'rxjs';

import { Infrastructure, Station, TypeCirculation, TypeMateriel, ConflictType, TypeCategorie, CodeChantier, Localite } from '@app/models';
import { Token, User } from '@app/auth';
import { LOCAL_STORAGE_TOKEN } from '../core/local-storage.token';

@Injectable({
  providedIn: 'root',
})
export class AppCacheService {
  private static readonly TOKEN = 'TOKEN';
  private static readonly USER = 'USER';
  private static readonly SELECTED_USER = 'SELECTED_USER';
  private static readonly STATIONS = 'STATIONS';
  private static readonly TYPES_CIRCULATION = 'TYPES_CIRCULATION';
  private static readonly INFRASTRUCTURE = 'INFRASTRUCTURE';
  private static readonly TYPE_MATERIEL = 'TYPES_MATERIEL';
  private static readonly TYPES_CONFLICTS = 'TYPES_CONFLITS';
  private static readonly CODES_CHANTIER = 'CODES_CHANTIER';
  private static readonly LOCALITES = 'LOCALITES';

  constructor(@Inject(LOCAL_STORAGE_TOKEN) private storage: StorageService) {}

  get(key: string) {
    return this.storage.get(key);
  }

  set(key: string, value: any) {
    this.storage.set(key, value);
  }

  clearCache() {
    this.storage.clear();
  }

  /**
   * Reset token and some information
   */
  resetCacheAtLogout() {
    this.storage.remove(AppCacheService.TOKEN);
    this.storage.remove(AppCacheService.USER);
    this.storage.remove(AppCacheService.STATIONS);
    this.storage.remove(AppCacheService.CODES_CHANTIER);
    this.storage.remove(AppCacheService.LOCALITES);

    this.resetCache();
  }

  // Reset storage without logout
  resetCache() {
    this.storage.remove(AppCacheService.TYPES_CIRCULATION);
    this.storage.remove(AppCacheService.INFRASTRUCTURE);
    this.storage.remove(AppCacheService.TYPE_MATERIEL);
    this.storage.remove(AppCacheService.TYPES_CONFLICTS);
  }

  //
  // ----- Token -----
  //

  setToken(token: Token | undefined): Token | undefined {
    this.storage.set(AppCacheService.TOKEN, token);
    return token;
  }

  getToken(): Token | undefined {
    return this.storage.get(AppCacheService.TOKEN);
  }

  //
  // ----- User -----
  //

  setUser(user: User): User {
    this.storage.set(AppCacheService.USER, user);
    return user;
  }

  getUser(): User {
    return this.storage.get(AppCacheService.USER);
  }

  // TODO 02/09/23 : To be deleted when we can handle the user from backend
  /**
   * Save the user clicked in local storage
   * @param user User we want to save in local storage
   * @returns User
   */
  setSelectedUser(user: User): User {
    this.storage.set(AppCacheService.SELECTED_USER, user);
    return user;
  }

  // TODO 02/09/23 : To be deleted when we can handle the user from backend
  /**
   * Handle the user we save in local storage when we click on him
   * @returns user
   */
  getSelectedUser(): User {
    return this.storage.get(AppCacheService.SELECTED_USER);
  }

  removeSelectedUser() {
    return localStorage.removeItem('SELECTED_USER');
  }

  //
  // ----- Station -----
  //

  /**
   * Store the given station in localStorage.
   * It will be added to the list of already stored stations, if any.
   */
  setStation(station: Station): Station {
    const stations = this.getStations() || [];
    const foundIndex = stations.findIndex(s => s.code === station.code);
    if (foundIndex !== -1) {
      stations[foundIndex] = station;
    } else {
      stations.push(station);
    }
    this.storage.set(AppCacheService.STATIONS, stations);

    return station;
  }

  /**
   * Return the requested station from localStorage.
   */
  getStation(stationCode: string | undefined): Station | undefined {
    const stations = this.getStations() || [];
    return stations.find(s => s.code === stationCode);
  }

  /**
   * Return the list of all stations stored in localStorage.
   */
  private getStations(): Station[] {
    return this.storage.get(AppCacheService.STATIONS);
  }

  /**
   * Get the codes chantier list from the cache
   * @returns observable of codes chantier list
   */
  getCodesChantier(): Observable<CodeChantier[]> {
    return of(this.storage.get(AppCacheService.CODES_CHANTIER));
  }

  /**
   * Set the codes chantier list in the local storage
   * @param codesChantier list of codes chantier
   */
  setCodesChantier(codesChantier: CodeChantier[]) {
    this.storage.set(AppCacheService.CODES_CHANTIER, codesChantier);
  }

  /**
   * Get the localites list from the cache
   * @returns observable of localites list
   */
  getLocalites(): Observable<Localite[]> {
    return of(this.storage.get(AppCacheService.LOCALITES));
  }

  /**
   * Set the localites list in the local storage
   * @param localites list of localites
   */
  setLocalites(localites: Localite[]) {
    this.storage.set(AppCacheService.LOCALITES, localites);
  }

  //
  // ----- Infrastructure -----
  //

  setStationInfrastructure(stationCode: string, infrastructure: Infrastructure): Observable<Infrastructure> {
    const station = this.getStation(stationCode);
    station.infrastructure = infrastructure;
    this.setStation(station);

    return of(infrastructure);
  }

  getStationInfrastructure(stationCode: string): Observable<Infrastructure> {
    const station = this.getStation(stationCode);
    return of(station.infrastructure);
  }

  getStationLastInfrastructure(stationCode: string): Observable<Infrastructure> {
    // @TODO: TEMP
    return this.getStationInfrastructure(stationCode);
  }

  getStationInfrastructureById(stationCode: string, parametrageId: number): Observable<Infrastructure> {
    const station = this.getStation(stationCode);
    return of(station.infrastructure && station.infrastructure.parametrageId === parametrageId ? station.infrastructure : null);
  }

  //
  // ----- Types Circulation -----
  //

  setStationTypesCirculation(stationCode: string, types: TypeCirculation[]): Observable<TypeCirculation[]> {
    const station = this.getStation(stationCode);
    station.typesCirculation = types;
    this.setStation(station);

    return of(types);
  }

  // DEPREC (19-FEB-2021): Skip caching now that this data depends on the current GOV's `parametrageId`
  getStationTypesCirculation__DEPREC(stationCode: string): Observable<TypeCirculation[]> {
    const station = this.getStation(stationCode);
    return of(station.typesCirculation);
  }

  //
  // ----- Types Materiel -----
  //

  setStationTypesMateriel(stationCode: string, types: TypeMateriel[]): Observable<TypeMateriel[]> {
    const station = this.getStation(stationCode);
    station.typesMateriel = types.sort((a, b) => {
      if (a.nom < b.nom) {
        return -1;
      } else if (a.nom > b.nom) {
        return 1;
      }
      return 0;
    });
    this.setStation(station);

    return of(types);
  }

  // DEPREC (19-FEB-2021): Skip caching now that this data depends on the current GOV's `parametrageId`
  getStationTypesMateriel__DEPREC(stationCode: string): Observable<TypeMateriel[]> {
    const station = this.getStation(stationCode);
    return of(station.typesMateriel);
  }

  //
  // ----- Types Conflits -----
  //

  setStationTypesConflits(stationCode: string, types: ConflictType[]): Observable<ConflictType[]> {
    const station = this.getStation(stationCode);
    station.typesConflit = types;
    this.setStation(station);

    return of(types);
  }

  getStationTypesConflits(stationCode: string): Observable<ConflictType[]> {
    const station = this.getStation(stationCode);
    return of(station.typesConflit);
  }

  //
  // ----- Types Categories -----
  //

  setStationTypesCategories(stationCode: string, types: TypeCategorie[]): Observable<TypeCategorie[]> {
    const station = this.getStation(stationCode);
    station.typesCategories = types;
    this.setStation(station);

    return of(types);
  }

  // DEPREC (19-FEB-2021): Skip caching now that this data depends on the current GOV's `parametrageId`
  getStationTypesCategories__DEPREC(stationCode: string): Observable<TypeCategorie[]> {
    const station = this.getStation(stationCode);
    return of(station.typesCategories);
  }

  //
  // ----- Infra schema -----
  //

  // TODO 28/04/2023 We save in the cache for now waiting the backend manage schema on parametrage
  setStationInfraSchema(stationCode: string, infraSchema: any): Observable<any> {
    const station = this.getStation(stationCode);
    station.infraSchema = infraSchema;
    this.setStation(station);

    return of(infraSchema);
  }

  getStationInfraSchema(stationCode: string): Observable<any> {
    const station = this.getStation(stationCode);
    return of(station.infraSchema);
  }

  //
  // ----- Timeline lock -----
  //

  // TODO 08/06/2023 Comment temporary until we did not correct the reset cache at logout

  // /**
  //  * Get the timeline lock
  //  * @returns the timeline lock
  //  */
  // getTimelineLock(): boolean {
  //   const timelineLock = this.storage.get(AppCacheService.TIMELINE_LOCK);
  //   if (timelineLock === undefined) {
  //     this.setTimelineLock(true);
  //   }
  //   return this.storage.get(AppCacheService.TIMELINE_LOCK);
  // }

  // /**
  //  * Set the timeline lock
  //  * @param timelineLock
  //  */
  // setTimelineLock(timelineLock: boolean) {
  //   this.storage.set(AppCacheService.TIMELINE_LOCK, timelineLock);
  // }
}
