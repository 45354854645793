/**
 * @file
 * Handle all backend interactions
 * pertaining to a "station".
 */
import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, map, tap, catchError } from 'rxjs/operators';

import { Station, TypeCirculation, TypeMateriel, Infrastructure, ConflictType, CodeChantier, Localite, Itineraire } from '@app/models';
import { BaseService } from './base-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StationService extends BaseService {
  // TODO 28/04/2023 Remove httpClient when the backend will manage schema on parametrage
  constructor(private http: HttpClient) {
    super();
  }

  //
  // ----- Station -----
  //

  getAllStations(): Observable<Station[]> {
    return this.backend.get('api/gares');
  }

  getStation(stationCode: string): Observable<Station> {
    return this.backend.get('api/gares').pipe(map(stations => stations.find(s => s.code === stationCode)));
  }

  storeStationInCache(station: Station): Station {
    return this.cache.setStation(station);
  }

  /**
   * Load additional station properties from other endpoints.
   * Store the loaded properties on the `Station` object stored in the cache.
   */
  loadExtraStationProps(stationCode: string): Observable<null> {
    return of(true).pipe(
      mergeMap(() => this.loadStationTypesConflits(stationCode)),
      map(() => null),
    );
  }

  /**
   * Load additional properties that depend on the current GOV's parametrageId.
   * Store the loaded properties on the `Station` object stored in the cache.
   */
  loadStationParametrage(stationCode: string, parametrageId?: number) {
    return forkJoin([
      this.loadStationTypesMateriel(stationCode, parametrageId),
      this.loadStationTypesCirculation(stationCode, { parametrageId }),
      this.loadStationTypesCategories(stationCode, parametrageId),
      // TODO 28/04/2023 Remove this call when the backend will manage schema on parametrage
      this.loadInfraSchema(stationCode),
    ]);
  }

  //
  // ----- Infrastructure -----
  //

  /**
   * Load the "current" infrastructure for the given station.
   */
  loadStationInfra__DEPREC(stationCode: string): Observable<Infrastructure> {
    // DEPREC (19-FEB-2021): Skip caching now that this data changes for every GOV?
    const getFromCache$ = of(null); // this.cache.getStationInfrastructure(stationCode);

    return getFromCache$.pipe(
      mergeMap(infra => (infra ? of(infra) : this.backend.get(`api/gares/${stationCode}/infrastructure/current`))),
      tap(infra => validateStationInfra(infra)),
      map(infra => processStationInfra(infra)),
      mergeMap((infra: Infrastructure) => this.cache.setStationInfrastructure(stationCode, infra)),
    );
  }

  /**
   * Load the latest infrastructure for the given station.
   */
  loadStationLatestInfra(stationCode: string): Observable<Infrastructure> {
    // return this.loadStationInfra__DEPREC(stationCode);

    return this.cache.getStationLastInfrastructure(stationCode).pipe(
      mergeMap(infra => (infra ? of(infra) : this.backend.get(`api/gares/${stationCode}/infrastructure/last`))),
      mergeMap((infra: Infrastructure) => this.cache.setStationInfrastructure(stationCode, infra)),
      map(infra => processStationInfra(infra)),
    );
  }

  /**
   * Load the infrastructure for the given station and the given parametrageId.
   */
  loadStationInfraByParametrageId(stationCode: string, parametrageId: number): Observable<Infrastructure> {
    //return this.loadStationInfra__DEPREC(stationCode);

    return this.cache.getStationInfrastructureById(stationCode, parametrageId).pipe(
      mergeMap(infra => (infra ? of(infra) : this.backend.get(`api/gares/${stationCode}/infrastructure?parametrageId=${parametrageId}`))),
      mergeMap((infra: Infrastructure) => this.cache.setStationInfrastructure(stationCode, infra)),
      map(infra => processStationInfra(infra)),
    );
  }

  /**
   * Load the station infrastructure for the given date.
   *
   * @param stationCode Station code.
   * @param date Date in the YYYY-MM-DD format.
   */
  loadStationInfraByDate(stationCode: string, date: string): Observable<Infrastructure> {
    return this.backend.get(`api/gares/${stationCode}/infrastructure?date=${date}`);
  }

  /**
   * Load station infra itineraires
   *
   * @param stationCode Station code.
   * @param infrastructureId The infrastructure id.
   * @param rids Rids of itineraires that we want
   */
  loadStationInfraItineraires(stationCode: string, infrastructureId: number, rids: string[]): Observable<Itineraire[]> {
    return this.backend.get(`api/gares/${stationCode}/infrastructure/${infrastructureId}/itineraires?rids=${rids}`);
  }

  //
  // ----- Types Circulation -----
  //

  loadStationTypesCirculation(stationCode: string, parameters?: { parametrageId?: number; date?: string }): Observable<TypeCirculation[]> {
    let url = `api/gares/${stationCode}/typecirculation`;

    if (parameters.parametrageId) {
      url += `?parametrageId=${parameters.parametrageId}`;
    }

    if (parameters.date) {
      url += `?date=${parameters.date}`;
    }

    // DEPREC (19-FEB-2021): Skip caching now that this data changes for every GOV?
    const getFromCache$ = of(null); // this.cache.getStationTypesCirculation__DEPREC(stationCode);

    return getFromCache$.pipe(
      mergeMap(types => (types ? of(types) : this.backend.get(url))),
      mergeMap(types => this.cache.setStationTypesCirculation(stationCode, types)),
    );
  }

  //
  // ----- Codes chantier -----
  //

  /**
   * Get the list of codes chantier from the cache or from the backend
   * @returns an observable of codes chantier list
   */
  loadCodesChantier(): Observable<CodeChantier[]> {
    return this.cache.getCodesChantier().pipe(
      mergeMap(codesChantier =>
        (codesChantier && codesChantier.length) > 0 ? of(codesChantier) : this.backend.get(`api/gares/codeschantier`),
      ),
      map((codesChantier: CodeChantier[]) => {
        this.cache.setCodesChantier(codesChantier);
        return codesChantier;
      }),
    );
  }

  //
  // ----- Localités -----
  //

  /**
   * Get the list of localites from the cache or from the backend
   * @returns an observable of localites list
   */
  loadLocalites(): Observable<Localite[]> {
    return this.cache.getLocalites().pipe(
      mergeMap(localites => ((localites && localites.length) > 0 ? of(localites) : this.backend.get(`api/gares/localites`))),
      map((localites: Localite[]) => {
        this.cache.setLocalites(localites);
        return localites;
      }),
    );
  }

  //
  // ----- Types Materiel -----
  //

  loadStationTypesMateriel(stationCode: string, parametrageId?: number): Observable<TypeMateriel[]> {
    const url = `api/gares/${stationCode}/typemateriel` + (parametrageId ? `?parametrageId=${parametrageId}` : '');

    // DEPREC (19-FEB-2021): Skip caching now that this data changes for every GOV?
    const getFromCache$ = of(null); // this.cache.getStationTypesMateriel__DEPREC(stationCode);

    return getFromCache$.pipe(
      mergeMap(types => (types ? of(types) : this.backend.get(url))),
      mergeMap(types => this.cache.setStationTypesMateriel(stationCode, types)),
    );
  }

  //
  // ----- Types Conflits -----
  //

  loadStationTypesConflits(stationCode: string): Observable<ConflictType[]> {
    return this.cache.getStationTypesCategories__DEPREC(stationCode).pipe(
      mergeMap(types => (types ? of(types) : this.backend.get('api/plandetransport/conflits'))),
      mergeMap(types => this.cache.setStationTypesConflits(stationCode, types)),
    );
  }

  //
  // ----- Types Categories -----
  //

  loadStationTypesCategories(stationCode: string, parametrageId?: number): Observable<any> {
    const url = `api/gares/${stationCode}/categories` + (parametrageId ? `?parametrageId=${parametrageId}` : '');

    // DEPREC (19-FEB-2021): Skip caching now that this data changes for every GOV?
    const getFromCache$ = of(null); // this.cache.getStationTypesCategories__DEPREC(stationCode)

    return getFromCache$.pipe(
      mergeMap(types => (types ? of(types) : this.backend.get(url))),
      mergeMap(types => this.cache.setStationTypesCategories(stationCode, types)),
    );
  }

  //
  // ----- Infra Schema -----
  //

  loadInfraSchema(stationCode: string): Observable<any> {
    const obs$ = this.http.get(`/assets/img/station-schemas/infra-schema-${stationCode}.svg`, { responseType: 'text' });

    return this.cache.getStationInfraSchema(stationCode).pipe(
      mergeMap(schema => (schema ? of(schema) : obs$)),
      mergeMap(schema => this.cache.setStationInfraSchema(stationCode, schema)),
      catchError(() => of(undefined)),
    );
  }

  // ------------------

  /**
   * Re-expose the resetCache method to avoid having to inject
   * the AppCache service into the consumers of `StationService`.
   */
  resetCache() {
    this.cache.resetCache();
  }
}

//
// Local helper function
//

/**
 * Process the station infrastructure returned by the backend:
 *   - Delete some properties
 *   - Clean up other properties
 */
function processStationInfra(infra: Infrastructure): Infrastructure {
  // Only keep specific infra properties
  const { id, codeGare, listVoieAQuai, listItineraire, listVoieEnLigne, parametrageId, listPRGare } = infra;
  const infraOk: Infrastructure = { id, codeGare, listVoieAQuai, listItineraire, listVoieEnLigne, parametrageId, listPRGare };

  // Fix some VAQ properties.
  infraOk.listVoieAQuai = infraOk.listVoieAQuai.map(vaq => ({
    ...vaq,
    nom: vaq.nom || '',
    positionGOV: vaq.positionGOV === null ? 1000 : vaq.positionGOV,
  }));
  // Sort VAQs.
  infraOk.listVoieAQuai.sort((vaq1, vaq2) => {
    return vaq1.positionGOV < vaq2.positionGOV ? -1 : 1;
  });
  // Fix VAQ `position` prop.
  infraOk.listVoieAQuai = infraOk.listVoieAQuai.map((vaq, i) => ({ ...vaq, position: i }));

  return infraOk;
}

/**
 * Validate that the given station infrastrucure contains a non-empty
 * list of "voies à quai" even after the voie IGNORE has been removed.
 */
function validateStationInfra(infra: Infrastructure) {
  // Remove "voie IGNORE"
  const listVoieAQuai = infra.listVoieAQuai.filter(vaq => vaq.nom !== 'IGNORE');

  // Raise a user-friendly error if the station infrastructure does not exist.
  if (listVoieAQuai.length === 0) {
    throw new Error('NO_IMPORTED_INFRASTRUCTURE');
  }
}
