/**
 * @file
 * Service handling APP-WIDE, settings-related features.
 */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Station } from '@app/models';
import { TSTSettings } from '@app/settings';
import { BaseService } from './base-service';
import { CookieService } from './cookie.service';
import { GovPdfDataOptions } from '@app/gov-ui/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  private static readonly PDF_EXPORT_SETTINGS = 'PDF_EXPORT_SETTINGS';
  private static readonly EQ_FONT_SIZE = 'EQ_FONT_SIZE';
  private static readonly TST_FILE_SETTINGS = 'TST_FILE_SETTINGS';
  private static readonly TIMELINE_LOCK = 'TIMELINE_LOCK';
  private static readonly FILTERED_VAQ = 'FILTERED_VAQ';
  private static readonly SHOW_ITI_SECONDAIRES = 'SHOW_ITI_SECONDAIRES';
  private filteredVaqMap: Map<string, string[]> = new Map<string, string[]>();

  constructor(private cookieService: CookieService) {
    super();
  }

  // Proxy method
  getStationFromCache(stationCode: string): Station | undefined {
    return this.cache.getStation(stationCode);
  }

  //
  // ----- Filter VAQ Settings -----
  //

  /**
   *
   * @param stationCode the code gare
   * @returns the list of Vaq filtered depending on the code gare
   */
  loadFilteredSettings(stationCode: string): string[] {
    const filteredVaqMapJSON = this.loadSetting(SettingsService.FILTERED_VAQ, { cacheType: 'cookie' });
    if (filteredVaqMapJSON) {
      const filteredVaqMap = JSON.parse(filteredVaqMapJSON) as [string, string[]][];
      // Here filteredVaqMap return ["codeGare", ["vaq.nom", "vaq.nom", ...]]
      // TODO 15 dec 2023 : This will need to be improved in order not to use array index
      const filteredVaqListByStation = filteredVaqMap.find(saved => saved[0] === stationCode);
      return filteredVaqListByStation ? filteredVaqListByStation[1] : undefined;
    }
  }

  /**
   * Store the filtered vaq for a specified station
   * @param stationCode the code gare
   * @param vaqList filtered vaq list
   */
  saveFilteredSettings(stationCode: string, vaqList: string[]) {
    this.filteredVaqMap.set(stationCode, vaqList);
    const savedMapJSON = JSON.stringify(Array.from(this.filteredVaqMap.entries()));
    this.saveSetting(SettingsService.FILTERED_VAQ, savedMapJSON, { cacheType: 'cookie' });
  }

  //
  // ----- PDF Export Settings -----
  //

  loadPdfExportSettings() {
    return this.loadSetting(SettingsService.PDF_EXPORT_SETTINGS, { cacheType: 'cookie' }) as GovPdfDataOptions;
  }

  savePdfExportSettings(settings: GovPdfDataOptions) {
    this.saveSetting(SettingsService.PDF_EXPORT_SETTINGS, settings, { cacheType: 'cookie' });
  }

  //
  // ----- Timeline lock -----
  //

  /**
   * Get the timeline lock
   * @returns the timeline lock
   */
  getTimelineLock(): boolean {
    const timelineLock = this.loadSetting(SettingsService.TIMELINE_LOCK) as boolean;
    if (timelineLock === undefined) {
      this.setTimelineLock(true);
    }
    return this.loadSetting(SettingsService.TIMELINE_LOCK) as boolean;
  }

  /**
   * Set the timeline lock
   * @param timelineLock
   */
  setTimelineLock(timelineLock: boolean) {
    this.saveSetting(SettingsService.TIMELINE_LOCK, timelineLock);
  }

  //
  // ----- Equilibre font size -----
  //

  /**
   *
   * @returns The equilibre font size
   */
  loadEqFontSize() {
    return this.loadSetting(SettingsService.EQ_FONT_SIZE) as number;
  }

  /**
   * Saves the equilibre font size
   * @param eqFontSize The equilibre font size
   */
  saveEqFontSize(eqFontSize: number) {
    this.saveSetting(SettingsService.EQ_FONT_SIZE, eqFontSize);
  }

  //
  // ----- Itineraires Secondaires Preferences-----
  //

  /**
   *
   * @returns user preferences to show/unshow the iti secondaires dots
   */
  loadShowItiSecondaires() {
    return this.loadSetting(SettingsService.SHOW_ITI_SECONDAIRES) as boolean;
  }

  /**
   * Saves the user preferences to show/unshow the iti secondaires dots
   * @param showItiSecondaires boolean to show/unshow the dots
   */
  saveShowItiSecondaires(showItiSecondaires: boolean) {
    this.saveSetting(SettingsService.SHOW_ITI_SECONDAIRES, showItiSecondaires);
  }

  //
  // ----- TST File Settings -----
  //

  /**
   * Return the settings to customize the columns in the TST file
   * @returns tst settings
   */
  loadTstSettings(stationCode: string): Observable<TSTSettings> {
    return this.backend.get(`api/gares/${stationCode}/tst`);
  }

  /**
   * Update TST Settings
   * @param stationCode station Code
   * @param tstSettings TST Settings
   * @returns TSTSettings observable
   */
  updateTSTSettings(stationCode: string, tstSettings: TSTSettings): Observable<TSTSettings> {
    return this.backend.patch(`api/gares/${stationCode}/tst/${tstSettings.id}`, tstSettings);
  }

  //
  // Private
  //

  /**
   * Get a setting from a local cache (cookie or localStorage).
   */
  private loadSetting(key: string, opts?: { cacheType: 'cookie' | 'localStorage' }) {
    const cacheType = opts?.cacheType || 'localStorage';
    return cacheType === 'localStorage' ? this.cache.get(key) : this.cookieService.get(key);
  }

  /**
   * Save a setting into a local cache (cookie or localStorage).
   */
  private saveSetting(key: string, value: any, opts?: { cacheType: 'cookie' | 'localStorage' }) {
    const cacheType = opts?.cacheType || 'localStorage';
    return cacheType === 'localStorage' ? this.cache.set(key, value) : this.cookieService.set(key, value);
  }
}
